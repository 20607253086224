import React, { useMemo } from "react"

import { useHistory } from "react-router-dom"

import { TEMPLATE_TYPES } from "../../pages/components/CustomTemplateSign/constants"

export const AuthTabs = ({
  type = TEMPLATE_TYPES.signinSocial,
  toggleLocalTab,
}) => {
  const history = useHistory()

  const activeTab = useMemo(() => {
    return type === TEMPLATE_TYPES.signup ? 1 : 0
  }, [type])

  const handleNavigate = tab => {
    if (toggleLocalTab) {
      return toggleLocalTab()
    }
    const path = tab === 0 ? "/sign-in" : "/sign-up"
    if (activeTab !== tab) history.push(path)
  }

  return (
    <ul className="ctsign__left__header__tabs">
      <li
        data-testid="loginTab"
        className={`ctsign__left__header__tabs-item ${
          activeTab === 0 ? "item-active" : ""
        }`}
        onClick={() => handleNavigate(0)}>
        Login
      </li>
    </ul>
  )
}

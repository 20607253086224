import React, { lazy, Suspense } from "react"

import { Redirect, Switch, Route } from "react-router-dom"

import { SIDEBAR_MENU } from "@constants/sidebar"

import { ChangePasswordModal } from "./components/ChangePasswordModal"
import {
  ArchivePage,
  Auth0ContentPage,
  ComingUpPage,
  InboxPage,
  NewSignInPage,
  // NewSignUpPage,
  TeeupPreviewPage,
  TrashPage,
} from "./pages"
import ChangeTempPassword from "./pages/ChangeTempPassword"
import ContactMethods from "./pages/ContactMethods"
import { ForgotPassword } from "./pages/ForgotPassword"
import MessagesPage from "./pages/MessagesPage"
// import SignUp from "./pages/SignUpPage"
import VersePage from "./pages/VersePage"
import PrivateRoute from "./routes/PrivateRoute"
import PublicRoute from "./routes/PublicRoute"

const MePage = lazy(() => import("./pages/MePage"))
const VerifyPage = lazy(() => import("./pages/VerifyPage"))
const SlackLogin = lazy(() => import("./pages/SlackLogin"))
const SettingsPage = lazy(() => import("./pages/SettingsPage"))
const CalendarPage = lazy(() => import("./pages/CalendarPage"))
const ChangePassword = lazy(() => import("./pages/ChangePassword"))
const VerifyInvitation = lazy(() => import("./pages/VerifyInvitation"))
const SignInEmail = lazy(() => import("./pages/SignInPage/SignInEmail"))
const SignInSocial = lazy(() => import("./pages/SignInPage/SingleSignInPage"))
const MyProfileNd = lazy(() => import("./pages/MyProfileNd/MyProfileNd"))
const Profile = lazy(() => import("./pages/Profile"))
const NotificationsPage = lazy(() => import("./pages/NotificationsPage"))
const ContactsPageNd = lazy(() => import("./pages/ContactPageNd/ContactPageNd"))
const UserProfilePage = lazy(() => import("./pages/ProfilePage"))
const NotFoundPage = lazy(() => import("./pages/NotFoundPage"))
const TeeUpPage = lazy(() => import("./pages/TeeUpPage"))

const useRoutes = () => {
  return (
    <Suspense fallback={<span>loading...</span>}>
      <Switch>
        {/*new route for slack integration IN DEVELOPMENT*/}
        <PublicRoute path="/slack-integration" component={SlackLogin} exact />
        <PublicRoute
          isDisabledRedirect
          isSharedLink
          path="/s/:slug"
          component={TeeupPreviewPage}
          exact
        />
        <PublicRoute
          isDisabledRedirect
          path="/t/:slug"
          component={TeeupPreviewPage}
          exact
        />
        <PublicRoute
          path="/new-sign-in-email"
          component={NewSignInPage}
          exact
        />
        <PublicRoute path="/sign-in-with" component={Auth0ContentPage} exact />
        <PublicRoute path="/sign-up-with" component={Auth0ContentPage} exact />
        {/* <PublicRoute path="/sign-up-email" component={NewSignUpPage} /> */}
        <PublicRoute path="/sign-in" component={SignInSocial} exact />
        <PublicRoute path="/sign-in-email" component={SignInEmail} exact />
        {/* <PublicRoute path="/sign-up" component={SignUp} exact /> */}
        <PublicRoute path="/forgot-password" component={ForgotPassword} exact />
        <PublicRoute
          path="/forgot-password/confirm/:token"
          component={ForgotPassword}
          exact
        />
        <PublicRoute
          path="/verify-invitation"
          component={VerifyInvitation}
          exact
        />
        <Route path="/users/email/delete/:token" component={InboxPage} exact />
        <Route
          path="/users/email/delete/cancel/:token"
          component={InboxPage}
          exact
        />

        <PrivateRoute
          path={SIDEBAR_MENU.INBOX.PATHNAME}
          component={InboxPage}
          exact
        />
        <PrivateRoute
          path={SIDEBAR_MENU.ARCHIVE.PATHNAME}
          component={ArchivePage}
          exact
        />
        <PrivateRoute
          path={SIDEBAR_MENU.TRASH.PATHNAME}
          component={TrashPage}
          exact
        />
        <PrivateRoute
          path={SIDEBAR_MENU.COMING_UP.PATHNAME}
          component={ComingUpPage}
          exact
        />
        {/* new path for profile */}
        <PrivateRoute
          path={SIDEBAR_MENU.MY_PROFILE.PATHNAME}
          component={MyProfileNd}
          exact
        />
        <PrivateRoute
          path={SIDEBAR_MENU.MY_PROFILE_2.PATHNAME}
          component={Profile}
          exact
        />

        <PrivateRoute path="/me" component={MePage} exact />
        <PrivateRoute path="/verse" component={VersePage} exact />
        <PrivateRoute path="/messages" component={MessagesPage} exact />
        <PrivateRoute
          path="/notifications"
          component={NotificationsPage}
          exact
        />
        <PrivateRoute
          path={SIDEBAR_MENU.CALENDAR.PATHNAME}
          component={CalendarPage}
          exact
        />
        {/*temp path for contacts page*/}
        <PrivateRoute
          path={SIDEBAR_MENU.CONTACTS.PATHNAME}
          component={ContactsPageNd}
          exact
        />
        <PrivateRoute
          path={SIDEBAR_MENU.SETTINGS.PATHNAME}
          component={SettingsPage}
          exact
        />
        <PrivateRoute
          path="/change-password"
          component={ChangePassword}
          exact
        />
        <PublicRoute path="/" exact>
          <Redirect to="/sign-in" />
        </PublicRoute>
        <PrivateRoute path="/contact" component={ContactMethods} exact />
        <PrivateRoute path="/teeUps/:id" component={TeeUpPage} exact />
        <PrivateRoute path="/profile/:id" component={UserProfilePage} exact />
        <PrivateRoute
          path="/users/email/resetpassword/:token"
          component={ChangePasswordModal}
          exact
        />

        <Route
          path="/verify/:token"
          render={props => <VerifyPage {...props} />}
        />
        <Route
          path="/change-temp-password"
          render={props => <ChangeTempPassword {...props} />}
        />

        <Route path="/not-found" component={NotFoundPage} />
        <Route path="*" component={NotFoundPage} />
      </Switch>
    </Suspense>
  )
}

export default useRoutes
